import React, { useState, ChangeEvent } from "react"
import * as Feather from "react-feather"

type Props = {
  headline?: string
}

type EncodeProps = {
  [name: string]: string
}

const encode = (data: EncodeProps) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default ({ headline = "" }: Props) => {
  const [values, setValues] = useState({ email: "", message: "" })
  const [sent, setSent] = useState(false)

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.currentTarget
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values })
    })
      .then(() => setSent(true))
      .catch((error) => console.log(error))

    e.preventDefault()
  }
  const styles = {
    // background: 'url(../../illustration-mailbox.svg) no-repeat bottom center',
    // backgroundSize: '200px'
  }

  return (
    <div className="container mx-auto flex flex-col items-center" style={styles}>
      <h2 className="m-auto my-4 text-2xl md:text-4xl uppercase font-black text-center">Kontakt</h2>
      <div className="bg-green-500 w-24 h-1 mx-auto mb-8"></div>
      <form
        className=""
        data-netlify="true"
        method="POST"
        action="/"
        name="contact"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p className="hidden">
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        <div className="mb-4">
          <label className="block text-gray-400 text-sm font-bold mb-2" htmlFor="email">
            Email Addresse
          </label>
          <input
            className="bg-gray-300 appearance-none border-2 border-gray-200 rounded-sm w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cyan"
            id="email"
            type="text"
            placeholder="Email"
            name="email"
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-400 text-sm font-bold mb-2" htmlFor="message">
            Nachricht
          </label>
          <textarea
            className="bg-gray-300 appearance-none border-2 border-gray-200 rounded-sm w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cyan"
            id="message"
            placeholder="Ihre Nachricht"
            name="message"
            rows="10"
            cols="50"
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex flex-auto justify-center">
          <button
            className="bg-green-500 hover:bg-green-400 text-white uppercase text-sm font-bold py-2 px-4 rounded-sm focus:outline-none focus:shadow-outline"
            type="submit"
          >
            {sent ? "Nomoi schicken" : "Abschicken"}
          </button>
        </div>
      </form>
      {sent && <h2 className="m-8">Dankeschön! I möd mi donn bei dir!</h2>}
      <a
        className="hover:text-green-500 flex items-center hover:text-green-400"
        href="tel:‎00436803256330"
      >
        <Feather.Phone />
        <span className="pl-2 text-lg">‎+43 680 3256330</span>
      </a>
      {/* <img src={illustrationMailbox} className="object-contain w-full" /> */}
    </div>
  )
}
