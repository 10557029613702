import React from "react"

type FeaturedProps = {
  headline: string
  text: string
  image: any
}

export const FeatureImageRight = ({ headline, text, image }: FeaturedProps) => (
  <div className="md:col-span-10 md:col-start-3 flex flex-col-reverse md:flex-row">
    <div className="flex flex-col items-end justify-center text-white leading-relaxed md:col-start-4 md:col-span-4">
      <div>
        <h2 className="text-xl md:text-2xl mb-4 uppercase font-black">{headline}</h2>
        <p className="text-xs md:text-sm">{text}</p>
      </div>
    </div>
    <div className="flex justify-center z-10 relative px-16 md:py-0 md:px-8 md:col-span-3">
      <img src={image} alt="Illustration Repair" className="object-contain w-full h-full" />
    </div>
  </div>
)

export const FeatureImageLeft = ({ headline, text, image }: FeaturedProps) => (
  <div className="md:col-span-10 md:col-start-1 flex flex-col md:flex-row">
    <div className="flex justify-center z-10 relative px-16 md:py-0 md:px-8 md:col-start-2 md:col-span-3">
      <img src={image} alt="Illustration Smarthome" className="object-contain w-full h-full" />
    </div>
    <div className="flex flex-col items-start justify-center text-white leading-relaxed md:col-span-4">
      <div>
        <h2 className="text-xl md:text-2xl mb-4 uppercase font-black">{headline}</h2>
        <p className="text-xs md:text-sm">{text}</p>
      </div>
    </div>
  </div>
)
