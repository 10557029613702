import React, { Component } from "react"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import { LatLngTuple, Browser } from "leaflet"

type Props = {
  data: {
    lat: number
    len: number
    zoom: number
  }
}
export default ({ data }: Props) => {
  const position: LatLngTuple = [data.lat, data.len]
  const access_token = `pk.eyJ1IjoidGhvayIsImEiOiJjazllMmxmN2MwOHFxM2ttbDBmcGQzazd6In0.gsFtXL6mQxnrQ7TXMuo1HQ`

  const url = `https://api.mapbox.com/styles/v1/thok/ck9e0x5rc1dum1iu971myfhpz/tiles/256/{z}/{x}/{y}@2x?access_token=${access_token}`

  const mapService = {
    name: "Mapbox",
    attribution: "",
    url: url
  }

  return (
    <MapContainer
      center={position}
      zoom={data.zoom}
      scrollWheelZoom={false}
      dragging={false}
      touchZoom={true}
      className="h-128"
    >
      <TileLayer {...mapService} />
      <Marker position={position}>
        <Popup>
          Smarthome König <br /> IT & Smarthome Services
        </Popup>
      </Marker>
    </MapContainer>
  )
}
