import { motion } from "framer-motion"
import { graphql } from "gatsby"
import React from "react"
import * as Feather from "react-feather"
import { IndexQuery } from "../../types/graphql-types"
import ContactForm from "../components/contactform"
import { FeatureImageLeft, FeatureImageRight } from "../components/featuregroup"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Map from "../components/map"
import SEO from "../components/seo"
import illustrationBackup from "../images/illustration-backup.svg"
import illustrationDevices from "../images/illustration-devices.svg"
import illustrationNas from "../images/illustration-nas.svg"
import illustrationNetwork from "../images/illustration-network.svg"
import illustrationRepair from "../images/illustration-repair.svg"
import illustrationSecurity from "../images/illustration-security.svg"
import illustrationSmarthome2 from "../images/illustration-smarthome-2.svg"

type Props = {
  data: IndexQuery
}

export default ({ data }: Props) => {
  const position = {
    lat: 48.52018965370093,
    len: 13.45829795978668,
    zoom: 16
  }
  const phone = {
    number: "00436803256330",
    displayNumber: "+43 680 3256330"
  }

  return (
    <Layout siteTitle={data.site?.siteMetadata?.title || ""} phone={phone}>
      <SEO
        title={data.site?.siteMetadata?.title || ""}
        keywords={[`smarthome`, `koenig`, `nas`, `hausinstallation`, `elektriker`, `Andreas König`]}
      />
      <div className="bg-grey-200 relative border-green-500 border-b-4">
        <Hero data={data.file} />
      </div>
      <div className="pt-12 md:pt-24 pb-12 bg-grey-500 relative">
        <div className="container mx-auto mb-12 flex flex-col items-center">
          <a
            href="tel:‎00436803256330"
            className="hover:text-green-500 flex items-center md:hidden mb-4"
          >
            <Feather.Phone />
            <span className="pl-2 text-lg">+43 680 3256330</span>
          </a>
          <h2 className="m-auto my-4 text-2xl md:text-4xl uppercase font-black text-white text-center">
            Leistungen
          </h2>
          <div className="bg-green-500 w-24 h-1 mx-auto mb-4 md:mb-8"></div>
        </div>

        <div className="container mx-auto mb-24 flex flex-col items-center">
          <motion.div
            className="grid row-gap-16 md:row-gap-10 md:col-gap-10 md:row-gap-20 grid-cols-1 md:grid-cols-12"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <FeatureImageRight
              headline={`PC-Service & Reparatur`}
              text={`Fehlersuche, Reparatur und Upgrade von Notebook und Rechnern. Der Rechner spinnt? Keiner kennt se aus? Nix geht mehr? Neustart nutzt a nix? Richt ma ois wieder des Zeug. Notfals entsorg ma's!`}
              image={illustrationRepair}
            />
            <FeatureImageLeft
              headline={`Smarthome-Services`}
              text={`Einrichtung und Programmierung deiner Smarthome-Geräte und Anlage. Probleme mit deim Smarthome? Kriagn ma hin. I bin KNX-zertifiziert und arbeit mit Loxone-Smarthome. Dazua gibt's a saubere App-Anbindung und sicheren Zugriff über VPN.`}
              image={illustrationSmarthome2}
            />

            <FeatureImageRight
              headline={`Internet und Netzwerktechnik`}
              text={`WLAN, Gast-Netzwerke, VLAN, VPN, Unifi-Systeme: Professionelle und gscheite Lösungen, die funktionieren. Damit du net in deiner Freizeit den Router aus- und einstecken muast. Und na, für WLAN braucht ma koa Kabel. Nur zum Einrichten.`}
              image={illustrationNetwork}
            />
            <FeatureImageLeft
              headline={`IT-Sicherheit`}
              text={`Moi grundsätzlich: Unbedingt an Passwort-Manager verwenden. Für olles ondane im Umfeld von Haus-Automation, Server, NAS-Geräte: Schau ma, dass nur die Leit Zugriff haben, die oan brauchen. Und net Fremde im Netz umanond stiern.`}
              image={illustrationSecurity}
            />

            <FeatureImageRight
              headline={`NAS-Systeme`}
              text={`NAS = NetworkAttachedStorage. Die private Multimedia-Cloud im Wohnzimmer. Oder wo ma des NAS hoid aufstellt. Olle Daten dort, wo'st sie brauchst. Kennst di aus? Na? Dann ruaf o, i moch da des.`}
              image={illustrationNas}
            />
            <FeatureImageLeft
              headline={`Datensicherung`}
              text={`Aktive Backups am Synology NAS. Bevor's wieder mal zu spät is. Weil dann is vorbei
                  mit da Gaudi. Urlaubsfotos? Weg. Selfies? Weg. Kinderfotos? A weg. Und woast eh,
                  wovon's kane Fotos gibt, des is net passiert. So schnö san de Kinder weg...`}
              image={illustrationBackup}
            />
            <FeatureImageRight
              headline={`PC & Notebook-Komponenten`}
              text={`Notebooks, Desktop-PC, Tablets, Smartphone und Peripherie-Geräte. Ois, wos da moderne Mensch braucht. Mit Einbau & Konfiguration. Billig, oba net umsunst.`}
              image={illustrationDevices}
            />
          </motion.div>
        </div>
      </div>

      <div className="pt-12 pb-12 bg-grey-400 relative">
        <ContactForm />
      </div>

      <div className="bg-grey-400 relative">
        <Map data={position} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Index {
    file(relativePath: { eq: "images/01.jpg" }) {
      childImageSharp {
        fixed(width: 1400, height: 800) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        image
        siteUrl
        telephone
      }
    }
  }
`
