import { motion } from "framer-motion"
import React from "react"
import illustrationSmarthome from "../images/illustration-smarthome.svg"

type Props = {
  data: any
}

export default ({ data }: Props) => {
  return (
    <div className="container mx-auto flex flex-col items-center pt-8">
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex items-center text-grey-600">
          <div>
            <h1 className="m-auto my-4 text-4xl uppercase font-black">IT & Smarthome Services</h1>
            <h2 className="m-auto text-2xl">Professionell dahoam im Innviertel</h2>
          </div>
        </div>
        <div className="flex justify-center z-10 relative">
          <img
            src={illustrationSmarthome}
            alt="Illustration Smarthome"
            className="block mx-auto -mb-6 mt-6 h-full w-full"
          />
        </div>
      </motion.div>
    </div>
  )
}
